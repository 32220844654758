<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 130px; z-index: 1"
              color="success"
              small
              fab
              v-if="tienePermiso == 1"
              @click="agregarPaquete()"
              title="Agregar Paquete"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="info"
              small
              fab
              @click="generarLote()"
              title="Generar Lote"
            >
              <v-icon>fas fa-truck</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Tipo de Envio
                    <v-autocomplete
                      v-model="selectedTipoEnvio"
                      item-text="tipo_descripcion"
                      :items="tiposEnvios"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    De Bodega
                    <v-autocomplete
                      v-model="selectedBodegaOrigen"
                      item-text="bodega_nombre"
                      :items="bodegas"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    <div class="mt-5">
                      <v-btn
                        icon
                        title="Limpiar Filtros"
                        color="warning"
                        class="mx-4"
                        @click="limpiarFiltros"
                      ><v-icon>fas fa-broom</v-icon></v-btn>
                      <v-btn
                        title="Buscar"
                        color="info"
                        class="mx-4"
                        type="submit"
                        :loading="load"
                      ><v-icon>fas fa-search</v-icon> Buscar</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Paquetes -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="paquetes"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 8">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarAcepPaq(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>Aceptar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="(item.movimiento_estado_id == 4) || (item.movimiento_estado_id == 6)">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="generarEtiqueta(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>Generar Etiqueta</span>
            </v-tooltip>
            <v-tooltip bottom v-if="((item.movimiento_estado_id == 4 || item.movimiento_estado_id == 6) && item.paquete_estado_id != 3)">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="confirmarMovimiento(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-exchange-alt</v-icon>
                </v-btn>
              </template>
              <span>Generar Movimiento</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.paquete_tipo_id == 4">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="descargarRemito(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar Remito</span>
            </v-tooltip>
          </template>
          <!-- Color para el estado del paquete -->
          <template v-slot:[`item.paquete_estado_nombre`]="{ item }">
            <v-chip :color="getColorEstadoPaq(item.paquete_estado_id)" dark>{{ item.paquete_estado_nombre }}</v-chip>
          </template>
          <!-- Color para el estado del movimiento -->
          <template v-slot:[`item.movimiento_estado_nombre`]="{ item }">
            <v-chip :color="getColorEstadoPaq(item.movimiento_estado_id)" dark>{{ item.movimiento_estado_nombre }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Paquete -->
    <ModalVerPaquete 
      :ver="dialogVerPaquete"
      :datos="datosPaquete"
      :bodegasDestino="bodegasD"
      :bodegasOrigen="bodegas"
      @closeModalVer="cerrarModalVer"
    />
    <!-- Modal Aceptar Paquete -->
    <ModalAcepPaquete 
      :dialogActivo="dialogAceptPaq"
      @aceptarPaq="aceptarPaquete"
      @setearModalAceptPaq="setModalAceptPaq"
    />
    <!-- Modal Generar Movimientos Paquetes -->
    <ModalGenerarMovimientos 
      :dialogActivo="dialogMovim"
      :repartidores="repartidores"
      :bodegas="bodegasD"
      :id="selectedPaquete.paquete_id"
      @setearModalMovimientos="setModalMovimientos"
      @setPendientes="setearPendientes"
    />
    <!-- Modal Agregar Paquete -->
    <ModalAddPaquete 
      :dialogActivo="dialogPaquete"
      :bodegasOrig="bodegas"
      :bodegasDest="bodegasD"
      :tiposPaquetes="tiposEnvios"
      :prov="provincias"
      @setearModalNewPaquete="setModalNuevoPaquete"
      @confimPackage="confirmarPaquete"
    />
    <!-- Modal Generar Lote -->
    <ModalGenerarLote 
      :dialogActivo="dialogLote"
      :bodegasOrig="bodegas"
      :bodegasDest="bodegasD"
      :repartidores="repartidores"
      @setearModalAddLote="setModalNuevoLote"
      @confirmLote="confirmarLote"
    />
    <ModalConfirmarAccion 
      @ejecutarAccion="confirmarAccion"
    />
  </div>
</template>

<script>
  import FechaRango from '../../components/util/FechaRango.vue'
  import SearchDataTable from '../../components/util/SearchDataTable.vue'
  import ModalVerPaquete from '../../components/paquetes/ModalVerPaquete.vue'
  import ModalAcepPaquete from '../../components/paquetes/ModalAcepPaquete.vue'
  import ModalGenerarMovimientos from '../../components/paquetes/ModalGenerarMovimientos.vue'
  import ModalAddPaquete from '../../components/paquetes/ModalAddPaquete.vue'
  import ModalGenerarLote from '../../components/paquetes/ModalGenerarLote.vue'
  import ModalConfirmarAccion from '../../components/generales/ModalConfirmarAccion.vue'
  import moment from 'moment'
  import download from 'downloadjs'
export default {
  data() {
    return {
      panel: 0,
      tiposEnvios: [],
      selectedTipoEnvio: {},
      bodegas: [],
      bodegasD: [],
      selectedBodegaOrigen: {},
      estados: [],
      selectedEstado: {},
      fechaRango: [ null, null ],
      limpiarFecha: false,
      load: false,
      search: '',
      paquetes: [],
      headers: [
        { text: 'Id Paquete', align: 'center', value: 'paquete_id' },
        { text: 'Comp. Código', align: 'center', value: 'comp_codigo' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Destinatario', align: 'center', value: 'destinatario_nombre' },
        { text: 'Estado Paquete', align: 'center', value: 'paquete_estado_nombre' },
        { text: 'Estado Movimiento', align: 'center', value: 'movimiento_estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      provincias: [],
      repartidores: [],
      datosPaquete: {
        cliente: '',
        documento: '',
        telefono: '',
        direccion: '',
        localidad: '',
        observacion: '',
        deBodega: {},
        aBodega: {},
        itemsPaquete: []
      },
      dialogVerPaquete: false,
      selectedPaquete: {},
      dialogMovim: false,
      tienePermiso: 0,
      dialogPaquete: false,
      dialogLote: false,
      opcion: 0,
      selectedNuevoPaquete: {},
      bodyNuevoLote: {},
      datosMovim: {},
      dialogAceptPaq: false,
      sortBy: 'paquete_id',
      sortDesc: true
    }
  },
  components: {
    FechaRango,
    SearchDataTable,
    ModalVerPaquete,
    ModalGenerarMovimientos,
    ModalAddPaquete,
    ModalGenerarLote,
    ModalConfirmarAccion,
    ModalAcepPaquete
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.msjModalCarga = 'Iniciando Formulario...'
      this.$store.state.modalCarga = true
      // obtengo todos los datos del Formulario
      let initPeticion = await this.$store.dispatch('paquetes/initFormPaquetes')
      this.$store.state.modalCarga = false
      if (initPeticion.resultado == 1){
        this.tiposEnvios = initPeticion.tipos
        this.estados = initPeticion.estados
        this.bodegas = initPeticion.bodegasO
        this.bodegasD = initPeticion.bodegasD
        this.provincias = initPeticion.provincias
        this.repartidores = initPeticion.repartidores
        this.tienePermiso = initPeticion.permiso[0].tienePermiso
      }else{
        this.tiposEnvios = []
        this.estados = []
        this.bodegas = []
        this.bodegasD = []
        this.provincias = []
        this.repartidores = []
        this.$store.dispatch('executeSnackBar', {msj: initPeticion.message, color: 'error', activo: true})
        return
      }
    },
    getColorEstadoPaq(estado_id){
      if (estado_id == 2) return 'green'
      else if (estado_id == 3 ) return 'red'
      else return 'blue'
    },
    agregarPaquete(){
      this.opcion = 1
      // abrir el modal
      this.dialogPaquete = true
    },
    generarLote(){
      this.opcion = 2
      // abrir el modal
      this.dialogLote =true
    },
    async buscar(){
      if(this.fechaRango[0] && this.fechaRango.length == 2){
        this.load = true
        // asigno fechas
        let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
        let fechaHasta = ''
        if (this.fechaRango[1].length > 0){
          fechaHasta = moment(this.fechaRango[1]).format('DD/MM/YYYY')
        }
        // tipo de envio
        let tipoEnvio = 0
        if (Object.keys(this.selectedTipoEnvio).length > 0){
          tipoEnvio = this.selectedTipoEnvio.tipo_id
        }
        // bodega origen
        let deBodega = 0
        if (Object.keys(this.selectedBodegaOrigen).length > 0){
          deBodega = this.selectedBodegaOrigen.bodega_codigo
        }
        // estado de paquete
        let estadoPquete = []
        if (Object.keys(this.selectedEstado).length > 0){
          estadoPquete.push(this.selectedEstado.estado_id)
        }
        this.paquetes = []
        let estadoMov = []
        let bodyBusqueda = {
          fechaDesde: fechaDesde,
          fechaHasta: fechaHasta,
          tipoEnvio: tipoEnvio,
          deBodega: deBodega,
          estadoPquete, estadoPquete,
          usuario: this.$store.state.username,
          estadoMov: estadoMov,
          repartidor_id: 0
        }
        let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', bodyBusqueda)
        this.load = false
        if (paquetesPeticion.resultado == 1){
          this.paquetes = paquetesPeticion.paquetes
        }else{
          this.paquetes = []
          this.$store.dispatch('executeSnackBar', {msj: paquetesPeticion.msj, color: 'error', activo: true})
        }
      }
    },
    async visualizar(item){
      this.$store.state.msjModalCarga = 'Buscando Detalles...'
      this.$store.state.modalCarga = true
      this.datosPaquete.cliente = item.destinatario_nombre
      this.datosPaquete.documento = item.destinatario_nro_doc
      this.datosPaquete.telefono = item.destinatario_telefono
      this.datosPaquete.direccion = item.destinatario_domicilio
      this.datosPaquete.localidad = item.destinatario_localidad
      this.datosPaquete.observacion = item.observacion
      // obtengo bodega origen
      let bodegaOrig = this.bodegas.filter(element => element.bodega_codigo == item.bodega_origen)
      if (bodegaOrig.length > 0){
        this.datosPaquete.deBodega = bodegaOrig[0]
      }
      // obtengo la bodega destino
      let bodegaDes = this.bodegasD.filter(element => element.bodega_codigo == item.bodega_destino)
      if (bodegaDes.length > 0){
        this.datosPaquete.aBodega = bodegaDes[0]
      }
      this.datosPaquete.itemsPaquete = []
      let detallesPeticion = await this.$store.dispatch('paquetes/getDetallesPaquete', {paquete_id: item.paquete_id})
      this.$store.state.modalCarga = false
      if (detallesPeticion.resultado == 1){
        this.datosPaquete.itemsPaquete = detallesPeticion.paquetes
      }else{
        // mostrar el error y abrir el modal
        this.$store.dispatch('executeSnackBar', {msj: detallesPeticion.msj, color: 'error', activo: true})
      }
      this.dialogVerPaquete = true
    },
    async confirmarAcepPaq(item){
      this.opcion = 3
      this.selectedPaquete = item
      this.dialogAceptPaq = true
    },
    async aceptarPaquete(value){
      if (value.aceptar == true){
        this.$store.state.msjModalCarga = 'Aceptando Paquete...'
        this.$store.state.modalCarga = true
        let aceptarPeticion = await this.$store.dispatch('paquetes/aceptarPaquete', {paquete_id: this.selectedPaquete.paquete_id, observacion: value.observacion})
        this.$store.state.modalCarga = false
        if (aceptarPeticion.resultado == 1){
          this.$store.dispatch('executeSnackBar', {msj: 'El paquete fue aceptado exitosamente.', color: 'success', activo: true})
          setTimeout(() => [this.buscar()], 2000)
        }else{
          this.$store.dispatch('executeSnackBar', {msj: aceptarPeticion.msj, color: 'error', activo: true})
        }
      }
    },
    async generarEtiqueta(item){
      this.$store.state.msjModalCarga = 'Generando etiqueta...'
      this.$store.state.modalCarga = true
      // armo mi body para la API
      let body = {
        destinatario: item.destinatario_nombre,
        documento: item.destinatario_nro_doc,
        domicilio: item.destinatario_domicilio,
        localidad: item.destinatario_localidad,
        telefono: item.destinatario_telefono,
        paquete_id: item.paquete_id
      }
      let etiquetaPedido = await this.$store.dispatch('paquetes/generateLabel', body)
      this.$store.state.modalCarga = false
      if (etiquetaPedido.resultado == 1){
        download(etiquetaPedido.archivo, 'Etiqueta.pdf', 'application/pdf')
      }else{
        this.$store.dispatch('executeSnackBar', {msj: etiquetaPedido.msj, color: 'error', activo: true})
      }
    },
    confirmarMovimiento(item){
      this.opcion = 4
      this.selectedPaquete = item
      this.dialogMovim = true
    },
    descargarRemito(item){
      window.open('http://200.45.250.4:8800/bb_web/public/mayorista/pdfremito/' + item.comp_codigo, '_blank')
    },
    cerrarModalVer(value){
      this.dialogVerPaquete = value
    },
    setModalMovimientos(value){
      this.dialogMovim = value
    },
    async generarMovimiento(datos){
      this.$store.state.msjModalCarga = 'Grabando Movimiento...'
      this.$store.state.modalCarga = true
      // armo el body para la consulta
      let repartidor_id = 0
      if (Object.keys(datos.repartidor).length > 0){
        repartidor_id = datos.repartidor.repartidor_codigo
      }
      let body = {
        bodega_origen: this.selectedPaquete.bodega_actual_codigo,
        bodega_destino: datos.bodega.bodega_codigo,
        repartidor_id: repartidor_id,
        paquetes: this.selectedPaquete.paquete_id
      }
      // envio a generar el movimiento
      let generarMovimPeticion = await this.$store.dispatch('paquetes/generarMovimiento', body)
      this.$store.state.modalCarga = false
      if (generarMovimPeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'Movimiento creado con éxito.', color: 'success', activo: true})
      }else{
        this.$store.dispatch('executeSnackBar', {msj: generarMovimPeticion.message, color: 'error', activo: true})
      }
      this.dialogMovim = false
    },
    setModalNuevoPaquete(value){
      this.dialogPaquete = value
    },
    setModalNuevoLote(value){
      this.dialogLote = value
    },
    limpiarFiltros(){
      this.limpiarFecha = true
    },
    confirmarAccion(value){
      if (value == true){
        if (this.opcion == 1){
          this.grabarNuevoPaquete()
        }
        if (this.opcion == 2){
          this.grabarLote()
        }
        if (this.opcion == 4){
          this.generarMovimiento({bodega: this.datosMovim.bodega, repartidor: this.datosMovim.repartidor})
        }
      }else{
        this.selectedNuevoPaquete = {}
        this.bodyNuevoLote = {}
        this.datosMovim = {}
      }
    },
    confirmarPaquete(value){
      // guardo el body
      this.selectedNuevoPaquete = value
      // pregunto al usuario
      this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Generar Nuevo Paquete', texto: '¿Confirma la grabación del nuevo paquete?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
    },
    async grabarNuevoPaquete(){
      this.$store.state.msjModalCarga = 'Grabando el Nuevo Paquete...'
      this.$store.state.modalCarga = true
      let paquetePeticion = await this.$store.dispatch('paquetes/newPackage', this.selectedNuevoPaquete)
      this.$store.state.modalCarga = false
      if (paquetePeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'Nuevo paquete creado con Éxito.', color: 'success', activo: true})
        
      }else{
        this.$store.dispatch('executeSnackBar', {msj: paquetePeticion.msj, color: 'error', activo: true})
      }
      this.dialogPaquete = false
    },
    confirmarLote(value){
      this.bodyNuevoLote = value
      this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Generar Lote', texto: '¿Confirma la grabación del nuevo Lote?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
    },
    async grabarLote(){
      this.$store.state.msjModalCarga = 'Grabando Lote...'
      this.$store.state.modalCarga = true
      let lotePeticion = await this.$store.dispatch('paquetes/generarLote', this.bodyNuevoLote)
      this.$store.state.modalCarga = false
      if (lotePeticion.resultado == 1){
        this.$store.dispatch('executeSnackBar', {msj: 'Lote creado exitosamente.', color: 'success', activo: true})
      }else{
        this.$store.dispatch('executeSnackBar', {msj: lotePeticion.msj, color: 'error', activo: true})
      }
      this.dialogLote = false
    },
    setearPendientes(value){
      this.datosMovim = value
      if (value.movimientos.length > 0){
        this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Generar Movimiento de Paquete', texto: 'Existen movimientos pendientes de aceptar para el paquete seleccionado. Si procede con la grabación todos los movimientos pendientes se cancelarán. ¿Desea continuar?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
      }else{
        this.$store.dispatch('executeModalAccion', {activo: true, titulo: 'Generar Movimiento de Paquete', texto: 'Está generando un movimiento del paquete hacia la bodega ' + value.bodega.bodega_nombre + '. ¿Confirma esta acción?', colorTitulo: 'info', colorAceptar: 'success', colorCancelar: 'warning'})
      }
    },
    setModalAceptPaq(value){
      this.dialogAceptPaq = value
    }
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    dialogVerPaquete: function(){
      if (this.dialogVerPaquete == false){
        this.datosPaquete.cliente = ''
        this.datosPaquete.documento = ''
        this.datosPaquete.telefono = ''
        this.datosPaquete.direccion = ''
        this.datosPaquete.localidad = ''
        this.datosPaquete.observacion = ''
        this.datosPaquete.deBodega = ''
        this.datosPaquete.aBodega = ''
        this.datosPaquete.itemsPaquete = []
      }
    }
  },
}
</script>

<style>

</style>